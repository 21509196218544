/* printStyles.css */
/* @media all {
  .pagebreak {
    display: none;
  }
} */

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  /* .pagebreak {
    margin-top: 1rem;
    display: none;
    page-break-after: auto;
  } */

  .invisible-on-print {
    display: none;
  }

  .landscape{
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
    transform-origin: 50% 50%;
    page: landscape;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
