body {
  margin: 0;
  font-family: "Inter";
  font-size: "16px";
  color: "#364150";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.always-show-remove-icon {
  gap: 10px;
  display: flex;
  flex-direction: column-reverse;
}

.always-show-remove-icon .ant-upload-list-item {
  width: 280px;
}

.always-show-remove-icon .ant-upload-list-item-action {
  opacity: 1 !important;
  visibility: visible !important;
}