.placeholder-item {
  background: #fff;
  border-radius: 6px;
  margin: 0 auto;
  width: 100%;
  padding: 0 !important;
  box-sizing: border-box;
  .animated-background {
    border-radius: 3px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderAnimation;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
    background-size: 1600px 104px;
    position: relative;
    height: 20px;
  }
}

@keyframes placeHolderAnimation {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.rc-time-picker-panel {
  z-index: 100000 !important;
}

.rc-time-picker-input {
  border: none !important;
  font-size: 1rem !important;
  color: #364150 !important;
  font-weight: 400 !important;
}

html.disable-swipe-back {
  overflow: hidden;
  overscroll-behavior-x: none;
}

@media print {
  .page-root {
    width: 100.5% !important;
  }
}

.page-root {
  position: relative;
  width: 100%;
  height: 100%;
}
